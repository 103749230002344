import VueRouter from 'vue-router'

import LivePage from './pages/live'
import LoginPage from './pages/login'
import BindPage from './pages/bind'
import Playground from './pages/playground'

const routes = [
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/preview', name: 'Preview', component: LivePage },
  { path: '/live/:id', name: 'Live', component: LivePage },
  { path: '/bind', name: 'Bind', component: BindPage },
  { path: '/playground', name: 'Bind', component: Playground }
]

export default new VueRouter({
  mode: 'history',
  routes
})
