import Vue from 'vue'
Vue.config.devtools = false
Vue.config.productionTip = false

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import request from '@/libs/request'
const _token = localStorage.getItem('xiaozhumao_token')
if (_token) {
  request.setToken(_token)
}

// import AlloyFinger from 'alloyfinger'
// import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'
// Vue.use(AlloyFingerPlugin, {
//   AlloyFinger
// })

// import 'animate.css'
import '@/styles/global.scss'

import router from './router'

import App from './App.vue'

Vue.prototype.$getMeta = function (key) {
  if (!key || !(key in this.data)) {
    return ''
  }
  const val = this.data[key]
  if ('scale' === key) {
    return val || 1
  }
  return val
}

Vue.prototype.$http = request

window.log = function () {
  let args = Array.prototype.slice.call(arguments)
  args = args.map((arg) => {
    if (typeof arg === 'object') {
      try {
        arg = JSON.stringify(arg)
      } catch (err) {}
    }
    return arg
  })
  const arr = ['[xiaozhumao]', ...args]
  console.log.apply(console, arr)
}

window.addEventListener('DOMContentLoaded', () => {
  if (window.__bridge__) {
    console.log('Bridge =>', JSON.stringify(Object.keys(window.__bridge__)))
    if (window.__bridge__.getSupportFeatures) {
      const features = window.__bridge__.getSupportFeatures()
      console.log('Features =>', typeof features, JSON.stringify(features))
    }
  }
})

new Vue({
  render: (h) => h(App),
  router
}).$mount('#root')
