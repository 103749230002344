<template>
  <div class="bind" :style="{ display }">
    <h1>绑定直播间计划</h1>
    <input type="text" v-model="plainid" placeholder="输入您的直播计划" />
    <button @click="bind">绑定</button>
  </div>
</template>
<script>
const LS_KEY = 'PLAIN_ID'
export default {
  data() {
    return {
      plainid: '',
      display: 'none'
    }
  },
  created() {
    this.plainid = localStorage.getItem(LS_KEY)
    if (this.plainid) {
      this.goto()
    } else {
      this.display = 'flex'
    }
  },
  methods: {
    bind() {
      if (this.plainid) {
        localStorage.setItem(LS_KEY, this.plainid)
        this.goto()
      }
    },
    goto() {
      if (this.plainid) {
        location.href = `/live/${this.plainid}`
      }
    }
  }
}
</script>
<style>
.bind {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  position: absolute;
  font-size: 0.2rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bind input {
  font-size: 0.3rem;
  margin: 0.2rem 0;

  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.bind button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
</style>
