import glsl from './glsl'

export default class ChromaKeyVideo {
  constructor(opt = {}) {
    this.config = Object.assign(
      {
        container: null,
        color: '#04f404',
        similarity: 0,
        smoothness: 0
      },
      opt
    )

    this.glVars = {}
    this.state = this.$container = this.config.container
    this.running = false
    // this.create()
    this.createCanvas()
    this.createVideo()
    this.startDraw()
  }

  // create() {
  //   log('ChromaKeyVideo 开始创建')

  //   this.createCanvas()

  //   this.createVideo()

  //   // this.createWebGl()

  //   // ;[
  //   //   'audioprocess',
  //   //   'canplay',
  //   //   'canplaythrough',
  //   //   'complete',
  //   //   'durationchange',
  //   //   'emptied',
  //   //   'ended',
  //   //   'error',
  //   //   'loadeddata',
  //   //   'loadedmetadata',
  //   //   'pause',
  //   //   'play',
  //   //   'playing',
  //   //   'progress',
  //   //   'ratechange',
  //   //   'seeked',
  //   //   'seeking',
  //   //   'stalled',
  //   //   'suspend',
  //   //   // 'timeupdate',
  //   //   'volumechange',
  //   //   'waiting'
  //   // ].forEach((eventName) => {
  //   //   this.video.addEventListener(eventName, () => {
  //   //     console.log('**ChromaKey VideoEvent', eventName)
  //   //   })
  //   // })

  //   log('ChromaKeyVideo 创建完成')
  // }

  renew() {
    log('ChromaKeyVideo 开始重置')
    this.destroy()
    this.createCanvas()
    this.startDraw()
    log('ChromaKeyVideo 重置完成')
  }

  handleContextLost() {
    log('ChromaKeyVideo 上下文丢失')
    this.renew()
  }

  shakeBody() {
    clearInterval(this._shakeTimmer)
    this._shakeTimmer = setInterval(() => {
      this.canvas.style['width'] = `${99.9 + Math.random() * 0.1}%`
    }, 1000)
  }

  createVideo() {
    if (this.video) {
      log('ChromaKeyVideo video已存在,跳过创建')
      return
    }
    log('ChromaKeyVideo 创建video')
    this.video = document.createElement('video')
    this.video.preload = 'auto'
    this.video.playsInline = true
    this.video.autoplay = false
    this.video.muted = true
    this.video.setAttribute('crossorigin', 'anonymous')
  }

  createCanvas() {
    log('ChromaKeyVideo 创建canvas')
    if (this.canvas) {
      this.$container.removeChild(this.canvas)
      this.canvas = null
    }

    this.canvas = document.createElement('canvas')
    this.canvas.addEventListener('webglcontextlost', this.handleContextLost.bind(this))
    this.canvas.style =
      'position:absolute;top:0;left:0;width:100%;height:100%;background-color:transparent;object-fit:cover;'
    this.$container.appendChild(this.canvas)
  }

  createWebGl() {
    log('ChromaKeyVideo 创建webgl')
    const gl = this.canvas.getContext('webgl', {
      premultipliedAlpha: false
    })

    window.__gl = gl

    this.gl = gl

    const vs = gl.createShader(gl.VERTEX_SHADER)
    this.vs = vs
    gl.shaderSource(vs, 'attribute vec2 c; void main(void) { gl_Position=vec4(c, 0.0, 1.0); }')
    gl.compileShader(vs)

    const fs = gl.createShader(gl.FRAGMENT_SHADER)
    this.fs = fs
    gl.shaderSource(fs, glsl)
    gl.compileShader(fs)
    if (!gl.getShaderParameter(fs, gl.COMPILE_STATUS)) {
      console.error(gl.getShaderInfoLog(fs))
    }

    const prog = gl.createProgram()
    this.prog = prog
    gl.attachShader(prog, vs)
    gl.attachShader(prog, fs)
    gl.linkProgram(prog)
    gl.useProgram(prog)

    const vb = gl.createBuffer()
    this.vb = vb
    gl.bindBuffer(gl.ARRAY_BUFFER, vb)
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, 1, -1, -1, 1, -1, 1, 1]), gl.STATIC_DRAW)

    const coordLoc = gl.getAttribLocation(prog, 'c')
    gl.vertexAttribPointer(coordLoc, 2, gl.FLOAT, false, 0, 0)
    gl.enableVertexAttribArray(coordLoc)

    gl.activeTexture(gl.TEXTURE0)
    const tex = gl.createTexture()
    this.tex = tex
    gl.bindTexture(gl.TEXTURE_2D, tex)

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)

    this.glVars.texLoc = gl.getUniformLocation(prog, 'tex')
    this.glVars.texWidthLoc = gl.getUniformLocation(prog, 'texWidth')
    this.glVars.texHeightLoc = gl.getUniformLocation(prog, 'texHeight')
    this.glVars.keyColorLoc = gl.getUniformLocation(prog, 'keyColor')
    this.glVars.similarityLoc = gl.getUniformLocation(prog, 'similarity')
    this.glVars.smoothnessLoc = gl.getUniformLocation(prog, 'smoothness')
    this.glVars.spillLoc = gl.getUniformLocation(prog, 'spill')
  }

  destroyCanvas() {
    log('ChromaKeyVideo 销毁canvas')
    if (!this.canvas) {
      return
    }
    this.$container.removeChild(this.canvas)
    this.canvas = null
  }

  destroyWebgl() {
    log('ChromaKeyVideo 销毁webgl')
    if (!this.gl) {
      return
    }
    const gl = this.gl
    gl.bindBuffer(gl.ARRAY_BUFFER, null)
    gl.bindTexture(gl.TEXTURE_2D, null)
    gl.deleteShader(this.vs)
    gl.deleteShader(this.fs)
    gl.deleteProgram(this.prog)
    gl.deleteBuffer(this.vb)
    gl.deleteTexture(this.tex)
    this.gl = null
  }

  destroy() {
    log('ChromaKeyVideo 开始销毁')
    cancelAnimationFrame(this.rafId)
    this.destroyWebgl()
    this.destroyCanvas()
    log('ChromaKeyVideo 销毁完成')
  }

  startDraw() {
    log('ChromaKeyVideo startDraw')
    this.running = true
    let lastDrawAt = 0
    const FPS = 30 // 以30fps进行绘制，节省性能
    const step = 1000 / FPS
    const loop = () => {
      this.running = true
      const now = Date.now()
      if (now - lastDrawAt > step) {
        lastDrawAt = now
        this.draw()
      }
      cancelAnimationFrame(this.rafId)
      this.rafId = requestAnimationFrame(loop)
    }
    cancelAnimationFrame(this.rafId)
    this.rafId = requestAnimationFrame(loop)
  }

  stopDraw() {
    this.running = false
    cancelAnimationFrame(this.rafId)
    log('ChromaKeyVideo stopDraw')
  }

  draw() {
    const video = this.video

    if (!this.canvas) {
      return
    }

    if (!this.video) {
      return
    }

    if (this.video.readyState !== 4) {
      return
    }

    if (video.videoWidth === 0 || video.videoHeight === 0) {
      return
    }

    if (!this.gl) {
      this.createWebGl()
    }

    const canvas = this.canvas
    const gl = this.gl
    const config = this.config
    const glVars = this.glVars

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    gl.viewport(0, 0, video.videoWidth, video.videoHeight)
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, video)
    gl.uniform1i(glVars.texLoc, 0)
    gl.uniform1f(glVars.texWidthLoc, video.videoWidth)
    gl.uniform1f(glVars.texHeightLoc, video.videoHeight)

    const m = config.color.match(/^#([0-9a-f]{6})$/i)[1]

    gl.uniform3f(
      glVars.keyColorLoc,
      parseInt(m.substring(0, 2), 16) / 255,
      parseInt(m.substring(2, 4), 16) / 255,
      parseInt(m.substring(4, 6), 16) / 255
    )
    gl.uniform1f(glVars.similarityLoc, parseFloat(config.similarity))
    gl.uniform1f(glVars.smoothnessLoc, parseFloat(config.smoothness))
    gl.uniform1f(glVars.spillLoc, parseFloat(0))
    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4)
  }
}
