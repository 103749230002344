<template>
  <div class="app-component app-component_image" :style="{
    ...style
  }">
    <template v-if="data && data.source && data.source.length > 0">
      <img :src="data.source[currentIndex].url" />
    </template>
  </div>
</template>

<script>
import event from '@/event'

export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      currentIndex: 0,
      style: {}
    }
  },
  created() {
    this.timmer = null
    this.resetStyle()
    this.resize()

    event.$on('start', () => {
      this.currentIndex = 0
      this.resetStyle()
      this.resize()
    })
  },
  mounted() {
    this.runSchedule()
  },
  beforeDestroy() {
    clearInterval(this.timmer)
  },
  methods: {
    resetStyle() {
      const rotate = this.$getMeta('rotate') || 0
      this.style = {
        top: this.$getMeta('top'),
        right: this.$getMeta('right'),
        bottom: this.$getMeta('bottom'),
        left: this.$getMeta('left'),
        width: this.$getMeta('width'),
        height: this.$getMeta('height'),
        transform: `scale(${this.$getMeta('scale')}) rotate(${rotate}deg)`,
        transformOrigin: 'top left'
      }
    },
    resize() {
      if (this.data.width && !this.data.height && this.data.source.length === 1) {
        const img = document.createElement('img')
        img.src = this.data.source[0].url
        img.onload = () => {
          document.body.removeChild(img)
          const w = this.$el.clientWidth
          const renderHeightPixel = img.height * w / img.width
          const rootFontSize = parseFloat(getComputedStyle(document.documentElement)['font-size'].replace('px', ''))
          this.style.height = (renderHeightPixel / rootFontSize) + 'rem'
        }
        document.body.appendChild(img)
      }
    },
    runSchedule() {
      this.timmer = setInterval(() => {
        if (this.currentIndex >= this.data.source.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex++
        }
      }, (this.data.duration || 5) * 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-component_image {
  position: absolute;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
