<template>
  <div class="app" style="user-select: none">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  created() {
  },
  methods: {}
}
</script>

<style lang="scss" src="./index.scss">

</style>
