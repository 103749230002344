<template>
  <div
    class="app-component app-component_text"
    :style="{
      ...style
    }"
  >
    {{ data.label }}
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      style: {}
    }
  },
  created() {
    this.style = {
      top: this.$getMeta('top'),
      right: this.$getMeta('right'),
      bottom: this.$getMeta('bottom'),
      left: this.$getMeta('left'),
      width: this.$getMeta('width'),
      height: this.$getMeta('height'),
      ...this.data.params
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.app-component_text {
  position: absolute;
}
</style>
