<template>
  <div class="app-component app-component_products"></div>
</template>

<script>
import getProps from 'lodash/get'

export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    state: {
      type: Number,
      default: 0
    }
  },
  watch: {
    state() {
      if (1 === this.state) {
        log('开始执行小黄车任务')
        const delay = getProps(this.data, 'params.delay', 5)
        const list = getProps(this.data, 'params.list', [])
        window.__bridge__.injectJs(
          'buyin',
          `javascript:window.__startExplain && window.__startExplain(${Math.max(5, delay)},${JSON.stringify(list)})`
        )
      } else {
        log('停止执行小黄车任务')
        window.__bridge__.injectJs('buyin', 'javascript:window.__stopExplain && window.__stopExplain()')
      }
    }
  },
  data() {
    return {}
  },
  created() {
    log('小黄车配置', this.data.params)
  },
  mounted() {},
  beforeDestroy() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
