<template>
  <div
    class="app-component app-component_camera"
    :class="{ camera__mode: chromaKeySettings && chromaKeySettings.facingMode }"
    :style="{ ...style }"
  ></div>
</template>
<script>
import event from '@/event'
import { get as getProps } from 'lodash'
import ChromaKeyVideo from '@/libs/ChromaKeyVideo'

const Bridge = window.__bridge__ || null

export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      chromaKeySettings: null,
      style: {}
    }
  },
  created() {
    const defaultVal = {
      color: '#04f404',
      similarity: 0,
      smoothness: 0,
      enableChromaKey: true,
      facingMode: 'user'
    }
    this.chromaKeySettings = Object.assign(defaultVal, this.data.chromakey || {})
    log('camera chromeKeySetting =>', this.chromaKeySettings)
  },
  mounted() {
    this.style = {
      top: this.$getMeta('top'),
      right: this.$getMeta('right'),
      bottom: this.$getMeta('bottom'),
      left: this.$getMeta('left'),
      width: this.$getMeta('width'),
      height: this.$getMeta('height'),
      filter: this.$getMeta('filter')
    }
    this.createCamera()

    // event.$on('app:resume', () => {
    event.$on('app:prepare', () => {
      console.log('[Camera] resume trigger')
      this.createCamera()
    })
  },
  methods: {
    async createCamera() {
      log('[Camera] create 1')
      if (!Bridge) {
        log('[Camera] create 2')
        return
      }
      log('[Camera] create 3')

      const permission = Bridge.checkPermissions()
      let hasCamera = permission.camera

      log('[Camera] create 4')

      if (!hasCamera) {
        log('[Camera] create 5')
        hasCamera = await new Promise((resolve) => {
          event.$once('permission:camera', (state) => {
            log('权限回调', state)
            resolve(state)
          })
          Bridge.requirePermission(['camera'])
        })
      }

      log('[Camera] create 6')
      if (!hasCamera) {
        log('[Camera] create 7')
        log('未获得摄像头权限')
        return
      }

      log('已获得摄像头权限')

      if (this.stream) {
        log('[Camera] create 8')
        this.stream.getTracks().forEach(function (track) {
          track.stop()
        })
      }

      if (this.video) {
        log('[Camera] create 9')
        this.video.srcObject = null
        this.video.remove()
        this.video = null
      }

      if (this.chromaKeyVideo) {
        log('[Camera] create 10')
        this.chromaKeyVideo.destroy()
      }

      let stream

      try {
        stream = await window.navigator.mediaDevices.getUserMedia({
          video: {
            // facingMode: 'environment'
            facingMode: this.chromaKeySettings.facingMode || 'user'
          }
        })
      } catch (err) {
        log('获取摄像头失败', err.toString())
      }

      this.stream = stream

      await this.$nextTick()

      if (this.chromaKeySettings.enableChromaKey) {
        this.chromaKeyVideo = new ChromaKeyVideo({
          container: this.$el,
          ...this.chromaKeySettings
        })
        this.video = this.chromaKeyVideo.video
      } else {
        this.video = document.createElement('video')
        this.$el.appendChild(this.video)
      }
      this.video.srcObject = stream

      this.video.play()
    }
  }
}
</script>
<style lang="scss" scoped>
.app-component_camera {
  position: absolute;

  ::v-deep {

    canvas,
    video {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: transparent;
    }

    .camera__mode {

      video,
      canvas {
        transform: scaleX(-1);
      }
    }
  }
}
</style>
