import axios from 'axios'

class Request {
  token = null
  constructor() {}
  get(url, opt) {
    return this._send('GET', url, opt)
  }
  post(url, opt) {
    return this._send('POST', url, opt)
  }
  put(url, opt) {
    return this._send('PUT', url, opt)
  }
  _send(method, url, opt = {}) {
    const headers = opt.headers || {}
    if (this.token && !/^\/(live-configs|eos-product)/.test(url)) {
      opt.headers = Object.assign(headers, {
        Authorization: `Bearer ${this.token}`
      })
    }
    return axios({
      method,
      url,
      ...Object.assign({}, opt, {
        headers: undefined
      }),
      headers: opt.headers || {},
      data: Object.assign({}, opt, {
        headers: undefined
      })
    })
  }
  setToken(token) {
    this.token = token
    this.instance = axios.create({
      timeout: 5000,
      headers: { Authorization: `Bearer ${this.token}` }
    })
  }
}

const request = new Request()

export default request
