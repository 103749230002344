<template>
  <div class="app-page app-page_playground">
    <div class="ctrl-view">
      <div class="filter-form">
        <div class="filter-form_row">
          <!-- <button class="btn-generate" @click="upgrade" style="margin-right: 20px;display:inline-block;width:130px"
            :disabled="upgrading">
            {{ upgrading ? '拉取中' : '拉取最新直播计划' }}
          </button> -->
          <span class="txt">模板:</span>
          <template v-if="templates.length > 0">
            <div class="input-field" style="width: 200px">
              <select v-model="template">
                <option v-for="item of templates" :value="item" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
          </template>
          <template v-else>
            正在获取模板列表...
          </template>
        </div>
        <!-- 品类 -->
        <div class="filter-form_row">

          <template v-if="categories.length > 0">
            <span class="txt">品类:</span>
            <div class="input-field">
              <select v-model="category">
                <option v-for="item of categories" :value="item.name" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
          </template>
          <template v-else>
            正在获取分类列表...
          </template>
        </div>
        <!-- 声音类型 -->
        <!-- 
        <div class="filter-form_row">
          <span class="txt">音频类型:</span>
          <div class="input-field">
            <select v-model="voiceType" placeholder="全部">
              <option v-for="item of voiceTypeList" :value="item.value">{{ item.name }}</option>
            </select>
          </div>
        </div>
         -->
        <div class="filter-form_row filter-form_row__btns">
          <button class="btn-toggle-debug-overlay" @click="isDebugOverlayVisible = !isDebugOverlayVisible">切换标尺</button>
          <button class="btn-generate" @click="generate" :disabled="processing">
            {{ processing ? '预览生成中' : '预览' }}
          </button>
          <button class="btn-replace" @click="upgradeLiveConfigHandler">替换计划</button>
        </div>
      </div>
      <div class="render-view">
        <div class="iframe-wrap">
          <iframe src="/preview" frameborder="0" v-if="template" id="renderer"></iframe>
          <div class="debug-overlay" v-show="isDebugOverlayVisible"></div>
        </div>
        <div ref="logs" class="logs">
          <p class="row" v-for="(l, $index) of logs" :key="$index"><span class="t">[{{ l.t }}]</span>{{ l.msg }}</p>
        </div>
      </div>
    </div>
    <div class="action-overlay" v-if="isActionOverlayVisible">
      <div class="live-config-select-wrap">
        <template v-if="liveConfigs.length > 0">
          <span>选择需要替换的计划：</span>
          <select v-model="selectedLiveConfig">
            <option v-for="item of liveConfigs" :value="item" :key="item.id">{{ item.id }} {{ item.name }}
            </option>
          </select>
          <button @click="confirmUpgrade">替换</button>
          <button @click="cancelUpgrade">取消</button>
        </template>
        <template v-else>
          <span style="font-size:20px;color:#fff;">正在获取计划列表....</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { sample as pick } from 'lodash'

const LAYER_TYPE = {
  背景: 'W',
  挂件: 'P',
  人像: 'H',
  摆件: 'O',
  前景: 'F',
  banner: 'B'
}

export default {
  data() {
    return {
      template: null,
      category: null,
      templates: [],
      categories: [],
      voiceTypeList: [
        {
          name: '全部',
          value: null
        },
        {
          name: '男声',
          value: 'male'
        },
        {
          name: '女声',
          value: 'female'
        }
      ],
      voiceType: null,
      assets: [],
      processing: false,
      upgrading: false,
      actionType: 'update',
      isDebugOverlayVisible: false,
      isActionOverlayVisible: false,
      selectedLiveConfig: null,
      liveConfigs: [],
      logs: [],

    }
  },
  async created() {
    this.log('正在初始化')
    await this.fetchTemplate()
    this.log(`获取到${this.templates.length}个模板`)
    await this.fetchCategories()
    this.log(`获取到${this.categories.length}个分类`)
    this.log('已就绪')
    this.fetchAllLiveConfig()
  },
  methods: {
    cancelUpgrade() {
      this.isActionOverlayVisible = false
    },
    async confirmUpgrade() {
      const confirmed = window.confirm('该操作不可撤销,确定更新该计划?')
      console.log("confirmed", confirmed)
      if (!confirmed) {
        return
      }
      console.log('准备替换', this.currentPreviewConfig, this.selectedLiveConfig)
      const newLiveConfig = Object.assign({}, this.selectedLiveConfig)

      // 非UI组件
      const noneUIItems = newLiveConfig.item.filter(_item => !_item.layer)

      // 将新生成的UI组件合并至旧的计划配置中
      newLiveConfig.item = [].concat(this.currentPreviewConfig.map(item => {
        return Object.assign({}, item, {
          _id: undefined,
          id: undefined
        })
      }), noneUIItems)

      await this.upgradeLiveConfig(newLiveConfig)
      this.isActionOverlayVisible = false
    },
    async upgradeLiveConfigHandler() {
      this.isActionOverlayVisible = true
      this.liveConfigs = []
      await this.fetchAllLiveConfig()
    },
    async upgradeLiveConfig(data) {
      await axios.put(`/live-configs/${data.id}`, data)
      alert('更新完成')
    },
    log(msg) {
      const now = new Date()
      this.logs.push({
        t: now.toLocaleDateString() + ' ' + now.toLocaleTimeString(),
        msg: msg
      })

      this.$nextTick(() => {
        this.$refs.logs.scrollTop = this.$refs.logs.scrollHeight
      })
    },
    getLayerType(label) {
      if (LAYER_TYPE.hasOwnProperty(label)) {
        return LAYER_TYPE[label.toLowerCase()]
      }
      return null
    },
    async upgrade() {
      if (this.upgrading) {
        return
      }
      this.upgrading = true
      Promise.all([this.fetchTemplate(false), this.fetchAllAsset(false)]).then(() => {
        this.upgrading = false
        location.reload()
      })
    },
    async generate() {

      if (this.processing) {
        return
      }

      if (!this.category) {
        alert('请选择品类')
        return
      }

      this.processing = true

      this.log(`开始生成 [模板]: ${this.template.name}  [分类]: ${this.category} 的预览资源 `)

      const templateItems = this.template.item.filter(item => ['image', 'video', 'raw_video'].indexOf(item.type) > -1)

      const items = []

      let layerF

      for await (const item of templateItems) {
        try {
          const res = await axios.get(`/live-assets/search?layer=${item.layer}&category=${this.category}`)
          this.log(` ${item.layer} ${this.category} 获取到${res.data.length}条数据`)
          const list = res.data.filter(tmp => tmp.source && tmp.source.mime.indexOf(item.type) > -1)
          if (list.length > 0) {
            const sample = pick(list)
            if (item.layer === 'F') {
              layerF = sample
            }
            items.push(Object.assign({}, item, {
              source: [sample.source]
            }))
          }
        } catch (err) {
          this.log(`获取 ${item.layer} ${this.category} 失败`)
        }
      }

      this.log('推送配置')

      if (layerF && layerF.video_offset) {
        const { left, top } = layerF.video_offset
        const [layerH] = items.filter(item => item.layer === 'H')
        if (layerH) {
          try {
            const originLeft = parseFloat(layerH.left || 0)
            const originTop = parseFloat(layerH.top || 0)
            layerH.left = originLeft + parseFloat(left) + 'rem'
            layerH.top = originTop + parseFloat(top) + 'rem'
            if (!layerH.width) {
              layerH.width = '100%'
              layerH.height = '100%'
              layerH.right = 'unset'
              layerH.bottom = 'unset'
            }
            this.log(`人像偏移 width:${layerH.width} height:${layerH.height} top:${layerH.top} right:${layerH.right} bottom:${layerH.bottom} left:${layerH.left}`)
          } catch (err) {
            this.log('[err] 设置人像偏移异常 ' + err.message)
            this.log('[err] F >> ' + JSON.stringify(layerF))
            this.log('[err] H >> ' + JSON.stringify(layerH))
          }
        }
      }

      for (const item of items) {
        this.log(`[${item.layer} ${item.label}] ${item.source[0]._id} ${item.source[0].url}`)
      }

      this.currentPreviewConfig = items

      console.log('push items >>', items)

      const frame = document.getElementById('renderer')
      frame.contentWindow.__updateLiveConfig__(items)


      setTimeout(() => {
        this.processing = false
        this.log('生成完毕')
      }, 1000)
    },
    async getAsset(category, layerType) {
      return this.assets.filter((item) => {
        if ((item.is_all || item[category]) && item.layer === layerType) {
          return item
        }
      })
    },
    async fetchCategories() {
      const res = await axios.get('/live-categories')
      this.categories = res.data.filter(item => item.name !== '通用')
      this.category = this.categories[0].name
    },
    async fetchAllLiveConfig() {
      const res = await axios.get('/live-configs?_limit=200')
      this.liveConfigs = res.data
    },
    async fetchTemplate() {
      const res = await axios.get('/live-templates')
      this.templates = res.data
      this.template = this.templates[0]
    }
  }
}
</script>

<style lang="scss" src="./index.scss"></style>
